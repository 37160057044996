export const setAPIUrl = () => {
  // return  'https://api-task.knighthunt.com';

  const url = window.location.href;
  if (url.includes('localhost')) {
    return 'http://localhost:5465';
  } else if (url.includes('knighthunt')) {
    return 'https://api-task.knighthunt.com';
  }
}

export const getCurrentWorkspace = (workspaces) => {
  return workspaces.find(workspace => workspace.isCurrentlySelected);
}

export const getWorkspaceFromUrl = () => {
  try {
    const url = window.location.href;

    // Create URL object for better parsing
    const urlObject = new URL(url);

    // Split the pathname into segments and remove empty strings
    const pathSegments = urlObject.pathname
      .split("/")
      .filter((segment) => segment.length > 0);

    // Return null if no path segments exist
    if (pathSegments.length === 0) {
      return null;
    }

    // Get the first path segment as the workspace code
    const workspaceCode = pathSegments[0];

    // Validate the workspace code
    if (!workspaceCode || workspaceCode.length === 0) {
      return null;
    }

    return workspaceCode;
  } catch (error) {
    console.error("Error parsing workspace from URL:", error);
    return null;
  }
};
