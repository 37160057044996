import api from "@/config/axios.js";

export const manageDomains = async (integrationId, data) => {
  return await api.post(`/integrations/${integrationId}/domain-config`, data);
};

export const deleteDomain = async (integrationId, id) => {
  return await api.delete(`/integrations/${integrationId}/domain-config/${id}`);
};

export const deleteEmail = async (integrationId, id) => {
  return await api.delete(`/integrations/${integrationId}/domain-config/${id}`);
};

export const deleteKeyword = async (integrationId, id) => {
  return await api.delete(`/integrations/${integrationId}/domain-config/${id}`);
};

export const syncHistory = async (integrationId, page = 1, limit = 10) => {
  return await api.get(`/integrations/${integrationId}/sync-history`, {
    params: { page, limit },
  });
};

export const getAllSyncHistory = async (page = 1, limit = 10) => {
  return await api.get(`/integrations/all/sync-histories`, {
    params: { page, limit },
  });
};

export const reconnectIntegration = async (integrationId, data) => {
  return await api.post(`/integrations/${integrationId}/reconnect`, data);
};

export const disconnectIntegration = async (integrationId, data) => {
  return await api.post(`/integrations/${integrationId}/disconnect`, data);
};

export const getIntegrationInfo = async (integrationId) => {
  return await api.get(`/integrations/${integrationId}/info`);
};

export const updateIntegrationConfig = async (integrationId, data) => {
  return await api.put(`/integrations/${integrationId}/config`, data);
};

export const getIntegrationConfig = async (integrationId) => {
  return await api.get(`/integrations/${integrationId}/config`);
};

export const availableIntegrations = async () => {
  return await api.get("/integrations/ALL/status");
};

export const getIntegrationById = async (integrationId) => {
  return await api.get(`/integrations/${integrationId}`);
};

export const connectGoogleIntegration = async (scopes) => {
  return await api.post(`/integrations/google/auth`, {
    scopes: scopes,
  });
};

export const googleCallback = async (code, state) => {
  return await api.post(`/integrations/google/callback`, {
    code,
    state,
  });
};

export const startGmailSync = async (integrationId) => {
  return await api.post(`/sync/gmail/${integrationId}`);
};
