import React, {useEffect, useState} from "react";
import {
  ArrowLeft,
  Bell,
  HelpCircle,
  Pin,
  PinOff,
  Search,
  Command,
} from "lucide-react";
import {NotificationCenter} from "@/components/common/notifications/NotificationCenter.jsx";
import ProfileDropdown from "./profileDropdown.jsx";
import {Link, useLocation} from "react-router-dom";
import {getWorkspacePath, sidebarRoutes, getSubmenuContent} from "@/router/all.routers.jsx";
import {useAuth} from "@/contexts/AuthContext.jsx";
import SearchComponent from "../SearchComponent.jsx";
import {useProfileStore} from "@/store/slices/profile.slice.js";

const MenuItem = ({icon: Icon, label, path, shortCode}) => {
  const navigatePath = path.replace(':shortCode', shortCode);

  return (
    <Link
      to={navigatePath}
      className="flex items-center gap-2 overflow-hidden rounded-md px-2 py-1.5 text-left text-xs hover:bg-sidebar-accent hover:text-sidebar-accent-foreground group/menu-item relative"
    >
      <Icon className="h-4 w-4"/>
      <span className="truncate">{label}</span>
    </Link>
  );
};

const MenuGroup = ({title, children}) => (
  <div className="space-y-1">
    <h3 className="text-xs font-medium text-gray-500 px-2 h-8 flex items-center">
      {title}
    </h3>
    {children}
  </div>
);

const Submenu = ({routePath, isFixed, onPin, shortCode}) => {
  const submenuData = getSubmenuContent(routePath, shortCode);
  if (!submenuData) return null;

  return (
    <div className="flex flex-col h-full">
      <div className="p-3 flex justify-between items-center">
        <div className="grid flex-1 text-left text-sm leading-tight">
          <span className="truncate font-semibold">Personal Workspace</span>
          <span className="truncate text-xs">Selected</span>
        </div>
        <button
          className="inline-flex items-center rounded-md justify-center text-sm font-semibold cursor-pointer text-gray-900 text-opacity-50 hover:text-opacity-100"
          onClick={onPin}
        >
          {isFixed ? <PinOff className="w-5"/> : <Pin className="w-5"/>}
        </button>
      </div>
      <div className="flex flex-col gap-4 py-4 px-2.5 overflow-y-auto">
        {submenuData.groups.map((group, index) => (
          <MenuGroup key={`${group.title}-${index}`} title={group.title}>
            {group.items.map((item, itemIndex) => (
              <MenuItem
                key={`${item.label}-${itemIndex}`}
                icon={item.icon}
                label={item.label}
                path={item.path}
                shortCode={shortCode}
              />
            ))}
          </MenuGroup>
        ))}
      </div>
    </div>
  );
};

const SidebarItem = ({
                       item,
                       isActive,
                       currentSubmenu,
                       onSubmenuChange,
                       isSubmenuFixed,
                       onSubmenuPin,
                       shortCode
                     }) => {
  const itemPath = getWorkspacePath(item.path, shortCode);
  const hasSubmenu = getSubmenuContent(itemPath, shortCode);

  return (
    <li className="w-full relative group">
      <Link
        to={itemPath}
        className={`flex items-center justify-center py-3 text-gray-400 ${
          isActive ? "text-white bg-gray-800" : "hover:text-white hover:bg-gray-800"
        } rounded-lg`}
        title={item.label}
        onMouseEnter={() => hasSubmenu && onSubmenuChange(itemPath)}
        onClick={() => hasSubmenu && onSubmenuChange(itemPath)}
      >
        <item.icon className="w-6 h-6"/>
      </Link>

      {/* Hover bridge for submenu */}
      {hasSubmenu && !isSubmenuFixed && (
        <div
          className="hidden group-hover:block fixed left-12 w-4 h-screen top-0 bg-transparent"
          onMouseEnter={() => onSubmenuChange(itemPath)}
        />
      )}

      {/* Submenu */}
      {hasSubmenu && (currentSubmenu === itemPath || (isSubmenuFixed && currentSubmenu === itemPath)) && (
        <div
          className={`z-[50] fixed left-12 top-0 bg-white border-r border-gray-200 h-full overflow-y-auto transition-transform duration-300 ${
            isSubmenuFixed ? "translate-x-0" : ""
          }`}
          onMouseEnter={() => onSubmenuChange(itemPath)}
          onMouseLeave={() => !isSubmenuFixed && onSubmenuChange(null)}
        >
          <Submenu
            routePath={itemPath}
            isFixed={isSubmenuFixed}
            onPin={onSubmenuPin}
            shortCode={shortCode}
          />
        </div>
      )}
    </li>
  );
};

export default function AuthLayout({
                                     children,
                                     childrenHeader,
                                     classes = "",
                                     bodyClass = "",
                                     showSidebar = true,
                                   }) {
  const location = useLocation();
  const {isSubmenuFixed, setIsSubmenuFixed} = useAuth();
  const [showNotifications, setShowNotifications] = useState(false);
  const [currentSubmenu, setCurrentSubmenu] = useState(null);
  const [isSearchVisible, setSearchVisible] = useState(false);
  const {currentWorkspace} = useProfileStore();
  const {shortCode} = currentWorkspace || {};
  const hasSubmenu = getSubmenuContent(currentSubmenu || "", shortCode);


  useEffect(() => {
    // Set initial submenu state based on current path
    const currentRoute = sidebarRoutes.find(route =>
      location.pathname.includes(getWorkspacePath(route.path, shortCode))
    );
    if (currentRoute && isSubmenuFixed) {
      setCurrentSubmenu(getWorkspacePath(currentRoute.path, shortCode));
    }
  }, [location.pathname, shortCode]);


  const handleSubmenuChange = (path) => {
    if (path === null && !isSubmenuFixed) {
      setCurrentSubmenu(null);
    } else if (getSubmenuContent(path, shortCode)) {
      setCurrentSubmenu(path);
    }
  };

  const handleSubmenuPin = () => {
    if (!isSubmenuFixed) {
      // Pinning the menu
      setIsSubmenuFixed(true);
      localStorage.setItem("isSubmenuFixed", "true");
    } else {
      // Unpinning the menu
      setIsSubmenuFixed(false);
      localStorage.setItem("isSubmenuFixed", "false");
      setCurrentSubmenu(null);
    }
  };

  const handleSearchResult = (searchParams) => {
    // Implement search logic here
  };
  return (
    <>
      {/* Header */}
      <header className="z-40 pb-4 sticky top-0 bg-gray-50">
        <div className={`bg-white flex items-center justify-between py-2 gap-3 pe-3 
          ${isSubmenuFixed && currentSubmenu && hasSubmenu ? "ps-64" : "ps-16"} ${classes}`}>
          {isSearchVisible ? (
            <div className="relative me-auto flex flex-1 items-center justify-between space-x-3">
              <button
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                onClick={() => setSearchVisible(false)}
              >
                <ArrowLeft size={20} strokeWidth="2.5"/>
              </button>
              <SearchComponent onSearch={handleSearchResult}/>
            </div>
          ) : (
            <div className="flex flex-1 items-center w-full">
              {childrenHeader}
            </div>
          )}

          <div className="flex items-center space-x-3">
            {!isSearchVisible && (
              <button
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                onClick={() => setSearchVisible(true)}
              >
                <Search size={20} strokeWidth="2.5"/>
              </button>
            )}
            <button
              className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell size={20} strokeWidth="2.5"/>
            </button>
            {currentWorkspace && (
              <ProfileDropdown workspace={currentWorkspace}/>
            )}
          </div>
        </div>
      </header>

      {/* Sidebar */}
      {showSidebar && (
        <aside className="w-12 top-0 fixed z-40 h-full">
          <div className="bg-gray-900 flex flex-col items-center py-2 h-full">
            <nav className="flex-1 w-full">
              <ul className="flex flex-col items-center space-y-4">
                <li>
                  <Link
                    to="/all-workspaces"
                    className="inline-flex items-center size-12 rounded-md justify-center text-sm font-semibold cursor-pointer mx-2"
                  >
                    <div
                      className="flex aspect-square size-10 items-center justify-center rounded-lg text-sidebar-primary-foreground">
                      <Command className="w-6 h-6"/>
                    </div>
                  </Link>
                </li>
                {sidebarRoutes.map((item) =>
                    item.location === "sideBar" && (
                      <SidebarItem
                        key={item.path}
                        item={item}
                        isActive={location.pathname === getWorkspacePath(item.path, shortCode)}
                        currentSubmenu={currentSubmenu}
                        onSubmenuChange={handleSubmenuChange}
                        isSubmenuFixed={isSubmenuFixed}
                        onSubmenuPin={handleSubmenuPin}
                        shortCode={shortCode}
                      />
                    )
                )}
              </ul>
            </nav>

            <div className="mt-auto mb-4">
              <Link
                to="/help"
                className="flex items-center justify-center p-3 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg mx-2"
                title="Help & Support"
              >
                <HelpCircle className="w-6 h-6"/>
              </Link>
            </div>
          </div>
        </aside>
      )}

      {/* Notifications */}
      {showNotifications && (
        <NotificationCenter onClose={() => setShowNotifications(false)}/>
      )}

      {/* Main Content */}
      <div
        className={`${
          isSubmenuFixed && currentSubmenu && hasSubmenu ? "ps-64" : showSidebar ? "ps-16" : ""
        } pe-4 min-h-without-header ${bodyClass}`}
      >
        {children}
      </div>
    </>
  );
}