import { create } from "zustand";
import {
  availableIntegrations,
  connectGoogleIntegration,
  deleteDomain,
  deleteEmail,
  disconnectIntegration,
  getAllSyncHistory,
  getIntegrationById,
  getIntegrationConfig,
  getIntegrationInfo,
  googleCallback,
  manageDomains,
  reconnectIntegration,
  startGmailSync,
  syncHistory,
  updateIntegrationConfig,
} from "@/services/integration.service.js";

const LOADING_STATES = {
  AVAILABLE_INTEGRATIONS: "availableIntegrations",
  SYNC_HISTORY: "syncHistory",
  INTEGRATION_CONFIG: "integrationConfig",
  INTEGRATION_INFO: "integrationInfo",
  DOMAIN_MANAGEMENT: "domainManagement",
  EMAIL_MANAGEMENT: "emailManagement",
  KEYWORD_MANAGEMENT: "keywordManagement",
  GMAIL_SYNC: "gmailSync",
  CONNECTION: "connection",
};

export const useIntegrationStore = create((set, get) => ({
  loadingStates: {
    [LOADING_STATES.AVAILABLE_INTEGRATIONS]: false,
    [LOADING_STATES.SYNC_HISTORY]: false,
    [LOADING_STATES.INTEGRATION_CONFIG]: false,
    [LOADING_STATES.INTEGRATION_INFO]: false,
    [LOADING_STATES.DOMAIN_MANAGEMENT]: false,
    [LOADING_STATES.EMAIL_MANAGEMENT]: false,
    [LOADING_STATES.KEYWORD_MANAGEMENT]: false,
    [LOADING_STATES.GMAIL_SYNC]: false,
    [LOADING_STATES.CONNECTION]: false,
  },
  error: null,
  integrations: [],
  currentIntegration: {
    id: null,
    name: null,
    status: null,
    config: null,
    whiteListEmails: [],
    whiteListDomains: [],
    blackListEmails: [],
    blackListDomains: [],
    whiteListKeywords: [],
    blackListKeywords: [],
    scope: [],
    sync_history: [],
  },
  syncHistories: [],
  platform: null,
  newIntegrationModal: false,
  settingsModal: false,
  historyModal: false,
  serviceConnectionModal: false,
  missingScopes: null,
  missingIntegrationId: null,
  callback: null,
  requiredScopes: null,
  lastActivity: null,

  setSettingsModal: (isOpen) => set({ settingsModal: isOpen }),
  setHistoryModal: (isOpen) => set({ historyModal: isOpen }),
  setNewIntegrationModal: (isOpen) => set({ newIntegrationModal: isOpen }),
  setServiceConnectionModal: (isOpen) =>
    set({ serviceConnectionModal: isOpen }),
  setMissingScopes: (scopes) => set({ missingScopes: scopes }),
  setCallback: (callback) => set({ callback }),

  setPlatform: (platform) => set({ platform }),
  setCurrentIntegration: (integration) =>
    set({ currentIntegration: integration }),

  setIntegrations: (integrations) => set({ integrations }),

  setError: (error) => set({ error }),
  setLoadingState: (operation, isLoading) =>
    set((state) => ({
      loadingStates: {
        ...state.loadingStates,
        [operation]: isLoading,
      },
    })),

  getAvailableIntegrations: async () => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.AVAILABLE_INTEGRATIONS, true);
      const response = await availableIntegrations();
      set({ integrations: response });
      return response;
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.AVAILABLE_INTEGRATIONS, false);
    }
  },
  connectGoogleIntegration: async (scopes) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.CONNECTION, true);
      const response = await connectGoogleIntegration(scopes);
      set({});
      window.location.href = response?.url;
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.CONNECTION, false);
    }
  },
  disconnectIntegration: async (integrationId, body) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.CONNECTION, true);
      const response = await disconnectIntegration(integrationId, body);
      await get().getAvailableIntegrations();
      const allIntegrations = get().integrations;
      const updatedIntegrations = allIntegrations.filter((integration) => {
        if (integration.id !== integrationId) {
          integration.status = "INACTIVE";
        }
        return integration;
      });
      set({
        integrations: updatedIntegrations,
        settingsModal: null,
      });
      return response;
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.CONNECTION, false);
    }
  },
  googleCallback: async (code, state) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.CONNECTION, true);
      const response = await googleCallback(code, state);
      await get().getAvailableIntegrations();
      set({});
      return response;
    } catch (error) {
      const checkMissingScopes = error?.error?.type?.missingScopes || [];
      const checkMissingIntegrationId = error?.error?.type?.integrationId;
      if (checkMissingScopes.length > 0) {
        set({
          missingScopes: checkMissingScopes,
          missingIntegrationId: checkMissingIntegrationId,
          requiredScopes: error?.error?.type?.requiredScopes,
          callback: null,
        });
      } else {
        set({ error: error.message });
      }
    } finally {
      get().setLoadingState(LOADING_STATES.CONNECTION, false);
    }
  },
  getIntegrationById: async (integrationId) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.INTEGRATION_INFO, true);
      const response = await getIntegrationById(integrationId);
      set({ currentIntegration: response });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.INTEGRATION_INFO, false);
    }
  },
  getIntegrationConfig: async (integrationId) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.INTEGRATION_CONFIG, true);
      const response = await getIntegrationConfig(integrationId);
      set({
        currentIntegration: { ...get().currentIntegration, config: response },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.INTEGRATION_CONFIG, false);
    }
  },
  getIntegrationInfo: async (integrationId) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.INTEGRATION_INFO, true);
      const response = await getIntegrationInfo(integrationId);
      set({
        currentIntegration: {
          ...get().currentIntegration,
          whiteListEmails: response.whiteListEmails,
          whiteListDomains: response.whiteListDomains,
          blackListEmails: response.blackListEmails,
          blackListDomains: response.blackListDomains,
          whiteListKeywords: response.whiteListKeywords,
          blackListKeywords: response.blackListKeywords,
          ...response,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.INTEGRATION_INFO, false);
    }
  },
  updateIntegrationConfig: async (integrationId, config) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.INTEGRATION_CONFIG, true);
      const response = await updateIntegrationConfig(integrationId, config);
      set({
        currentIntegration: {
          ...get().currentIntegration,
          config: response?.config,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.INTEGRATION_CONFIG, false);
    }
  },
  manageDomains: async (integrationId, data) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.DOMAIN_MANAGEMENT, true);
      const response = await manageDomains(integrationId, data);
      const currentIntegration = get().currentIntegration;
      if (data.type === "whitelist" && response.whiteListDomains) {
        currentIntegration.blackListDomains =
          currentIntegration.blackListDomains.filter(
            (domain) => domain.id !== response.whiteListDomains.id,
          );
      } else if (response.blackListDomains) {
        currentIntegration.whiteListDomains =
          currentIntegration.whiteListDomains.filter(
            (domain) => domain.id !== response.blackListDomains.id,
          );
      }
      const pushTo =
        data.type === "whitelist" ? "whiteListDomains" : "blackListDomains";
      currentIntegration[pushTo].push(response[pushTo]);
      set({
        currentIntegration,
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.DOMAIN_MANAGEMENT, false);
    }
  },
  manageEmails: async (integrationId, data) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.EMAIL_MANAGEMENT, true);
      const response = await manageDomains(integrationId, data);
      const currentIntegration = get().currentIntegration;
      if (data.type === "whitelist" && response.whiteListEmails) {
        currentIntegration.blackListEmails =
          currentIntegration.blackListEmails.filter(
            (email) => email.id !== response.whiteListEmails.id,
          );
      } else if (response.blackListEmails) {
        currentIntegration.whiteListEmails =
          currentIntegration.whiteListEmails.filter(
            (email) => email.id !== response.blackListEmails.id,
          );
      }

      const pushTo =
        data.type === "whitelist" ? "whiteListEmails" : "blackListEmails";
      currentIntegration[pushTo].push(response[pushTo]);
      set({
        currentIntegration,
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.EMAIL_MANAGEMENT, false);
    }
  },
  manageKeywords: async (integrationId, data) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.KEYWORD_MANAGEMENT, true);
      const response = await manageDomains(integrationId, data);
      const currentIntegration = get().currentIntegration;
      if (data.type === "whitelist" && response?.whiteListKeywords) {
        currentIntegration.blackListKeywords =
          currentIntegration?.blackListKeywords?.filter(
            (keyword) => keyword.id !== response.whiteListKeywords.id,
          );
      } else if (response?.blackListKeywords) {
        currentIntegration.whiteListKeywords =
          currentIntegration?.whiteListKeywords?.filter(
            (keyword) => keyword.id !== response.blackListKeywords.id,
          );
      }

      const pushTo =
        data.type === "whitelist" ? "whiteListKeywords" : "blackListKeywords";

      currentIntegration[pushTo].push(response[pushTo]);
      set({
        currentIntegration,
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.KEYWORD_MANAGEMENT, false);
    }
  },
  deleteDomain: async (integrationId, id) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.DOMAIN_MANAGEMENT, true);
      await deleteDomain(integrationId, id);

      const currentIntegration = get().currentIntegration;
      const whiteListDomains = currentIntegration.whiteListDomains.filter(
        (domain) => domain.id !== id,
      );
      const blackListDomains = currentIntegration.blackListDomains.filter(
        (domain) => domain.id !== id,
      );

      set({
        currentIntegration: {
          ...currentIntegration,
          whiteListDomains,
          blackListDomains,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.DOMAIN_MANAGEMENT, false);
    }
  },
  deleteEmail: async (integrationId, id) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.EMAIL_MANAGEMENT, true);
      await deleteEmail(integrationId, id);

      const currentIntegration = get().currentIntegration;
      const whiteListEmails = currentIntegration.whiteListEmails.filter(
        (email) => email.id !== id,
      );
      const blackListEmails = currentIntegration.blackListEmails.filter(
        (email) => email.id !== id,
      );

      set({
        currentIntegration: {
          ...currentIntegration,
          whiteListEmails,
          blackListEmails,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.EMAIL_MANAGEMENT, false);
    }
  },
  deleteKeyword: async (integrationId, id) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.KEYWORD_MANAGEMENT, true);
      await deleteEmail(integrationId, id);

      const currentIntegration = get().currentIntegration;
      const whiteListKeywords = currentIntegration.whiteListKeywords.filter(
        (keyword) => keyword.id !== id,
      );
      const blackListKeywords = currentIntegration.blackListKeywords.filter(
        (keyword) => keyword.id !== id,
      );

      set({
        currentIntegration: {
          ...currentIntegration,
          whiteListKeywords,
          blackListKeywords,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.KEYWORD_MANAGEMENT, false);
    }
  },
  startGmailSync: async (integrationId) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.GMAIL_SYNC, true);
      const response = await startGmailSync(integrationId);
      const allSyncHistories = get().syncHistories;
      response.provider = "GOOGLE";
      response.endTime = new Date().toISOString();
      allSyncHistories?.data?.unshift(response);
      allSyncHistories.pagination.total += 1;
      const allIntegrations = get().integrations;
      const currentIntegration = allIntegrations.find(
        (integration) => integration.id === integrationId,
      );
      currentIntegration.lastSync =
        response.endTime || new Date().toISOString();
      currentIntegration.lastSyncStatus = response.status;
      set({
        syncHistories: allSyncHistories,
        integrations: allIntegrations,
        lastActivity: response.endTime,
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.GMAIL_SYNC, false);
    }
  },
  syncHistory: async (integrationId, page, limit) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.SYNC_HISTORY, true);
      const response = await syncHistory(integrationId, page, limit);
      set({
        currentIntegration: {
          ...get().currentIntegration,
          sync_history: response?.data,
          lastActivity: response?.data?.[0]?.endTime,
        },
      });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.SYNC_HISTORY, false);
    }
  },
  reconnectIntegration: async (integrationId, data) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.CONNECTION, true);
      const response = await reconnectIntegration(integrationId, data);
      set({});
      window.location.href = response.url;
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.CONNECTION, false);
    }
  },
  getAllSyncHistory: async (page, limit) => {
    try {
      set({ error: null });
      get().setLoadingState(LOADING_STATES.SYNC_HISTORY, true);
      const response = await getAllSyncHistory(page, limit);

      const lastActivity = response?.data?.[0]?.endTime;
      set({ syncHistories: response, lastActivity });
    } catch (error) {
      set({ error: error.message });
      console.error("Error:", error);
    } finally {
      get().setLoadingState(LOADING_STATES.SYNC_HISTORY, false);
    }
  },
}));
