import {
    Home, RefreshCcw, AlertTriangle,
    AlertCircle, WifiOff, Clock, Lock, CheckSquare, Calendar, Search, Bell, Check
} from 'lucide-react';

const Error404 = () => {
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="text-center">
                <div className="flex justify-center mb-8">
                    <img src="https://picsum.photos/400/300" alt="404 Illustration" />
                </div>
                <h1 className="text-4xl font-bold mb-4">Page Not Found</h1>
                <p className="text-gray-600 mb-8 max-w-md mx-auto">
                    {`Oops! The page you're looking for doesn't exist or has been moved.`}
                </p>
                <div className="flex items-center justify-center space-x-4">
                    <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                        <Home className="h-4 w-4 mr-2" />
                        Back to Home
                    </button>
                    <button className="flex items-center px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50">
                        <RefreshCcw className="h-4 w-4 mr-2" />
                        Refresh Page
                    </button>
                </div>
            </div>
        </div>
    );
};

const Error500 = () => {
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="text-center">
                <div className="flex justify-center mb-8">
                    <AlertTriangle className="h-20 w-20 text-red-500" />
                </div>
                <h1 className="text-4xl font-bold mb-4">Something Went Wrong</h1>
                <p className="text-gray-600 mb-8 max-w-md mx-auto">
                    {`We're experiencing some technical difficulties. Our team has been notified and is working on it.`}
                </p>
                <div className="flex items-center justify-center space-x-4">
                    <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                        <Home className="h-4 w-4 mr-2" />
                        Back to Home
                    </button>
                    <button className="flex items-center px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50">
                        <RefreshCcw className="h-4 w-4 mr-2" />
                        Try Again
                    </button>
                </div>
            </div>
        </div>
    );
};

const NetworkError = () => {
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="text-center">
                <div className="flex justify-center mb-8">
                    <WifiOff className="h-20 w-20 text-orange-500" />
                </div>
                <h1 className="text-4xl font-bold mb-4">Connection Lost</h1>
                <p className="text-gray-600 mb-8 max-w-md mx-auto">
                    Please check your internet connection and try again.
                </p>
                <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mx-auto">
                    <RefreshCcw className="h-4 w-4 mr-2" />
                    Retry Connection
                </button>
            </div>
        </div>
    );
};

// Empty States Component
const EmptyStates = () => {
    return (
        <div className="grid grid-cols-2 gap-6 p-6">
            {/* Empty Tasks */}
            <div className="bg-white rounded-xl border p-8 text-center">
                <div className="flex justify-center mb-4">
                    <CheckSquare className="h-12 w-12 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium mb-2">No Tasks Yet</h3>
                <p className="text-gray-500 mb-6">
                    Create your first task to get started tracking your work.
                </p>
                <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mx-auto">
                    Create Task
                </button>
            </div>

            {/* Empty Calendar */}
            <div className="bg-white rounded-xl border p-8 text-center">
                <div className="flex justify-center mb-4">
                    <Calendar className="h-12 w-12 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium mb-2">No Upcoming Meetings</h3>
                <p className="text-gray-500 mb-6">
                    Schedule your first meeting to start collaborating with your team.
                </p>
                <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mx-auto">
                    Schedule Meeting
                </button>
            </div>

            {/* No Search Results */}
            <div className="bg-white rounded-xl border p-8 text-center">
                <div className="flex justify-center mb-4">
                    <Search className="h-12 w-12 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium mb-2">No Results Found</h3>
                <p className="text-gray-500 mb-6">
                    {`Try adjusting your search or filter to find what you're looking for.`}
                </p>
                <button className="flex items-center px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50 mx-auto">
                    Clear Filters
                </button>
            </div>

            {/* Empty Notifications */}
            <div className="bg-white rounded-xl border p-8 text-center">
                <div className="flex justify-center mb-4">
                    <Bell className="h-12 w-12 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium mb-2">All Caught Up!</h3>
                <p className="text-gray-500 mb-6">
                    {`You're up to date with all your notifications.`}
                </p>
                <div className="flex items-center justify-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-2" />
                    Last checked 2 minutes ago
                </div>
            </div>
        </div>
    );
};

// Loading States Component
const LoadingStates = () => {
    return (
        <div className="grid grid-cols-2 gap-6 p-6">
            {/* Content Loading */}


            {/* Card Loading */}
            <div className="bg-white rounded-xl border p-6">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-200 h-12 w-12"></div>
                    <div className="flex-1 space-y-4">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded"></div>
                            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Table Loading */}
            <div className="bg-white rounded-xl border p-6">
                <div className="animate-pulse space-y-4">
                    <div className="h-8 bg-gray-200 rounded w-full"></div>
                    {[1, 2, 3].map((row) => (
                        <div key={row} className="h-12 bg-gray-200 rounded w-full"></div>
                    ))}
                </div>
            </div>

            {/* Button Loading */}
            <div className="bg-white rounded-xl border p-6 flex items-center justify-center">
                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50 flex items-center" disabled>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                    Loading...
                </button>
            </div>
        </div>
    );
};

// Success/Failure Messages Component
const StatusMessages = () => {
    return (
        <div className="space-y-4 p-6">
            {/* Success Toast */}
            <div className="fixed top-4 right-4 bg-green-50 border border-green-200 rounded-lg p-4 flex items-start max-w-sm">
                <Check className="h-5 w-5 text-green-500 mt-0.5 mr-3" />
                <div>
                    <h3 className="font-medium text-green-900">Success!</h3>
                    <p className="text-sm text-green-700 mt-1">
                        Your changes have been successfully saved.
                    </p>
                </div>
            </div>

            {/* Error Toast */}
            <div className="fixed top-20 right-4 bg-red-50 border border-red-200 rounded-lg p-4 flex items-start max-w-sm">
                <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 mr-3" />
                <div>
                    <h3 className="font-medium text-red-900">Error</h3>
                    <p className="text-sm text-red-700 mt-1">
                        An error occurred while saving your changes.
                    </p>
                </div>
            </div>

            {/* Warning Message */}
            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                <div className="flex items-center">
                    <AlertTriangle className="h-5 w-5 text-yellow-500 mr-3" />
                    <div>
                        <h3 className="font-medium text-yellow-900">Warning</h3>
                        <p className="text-sm text-yellow-700 mt-1">
                            You have unsaved changes. Are you sure you want to leave?
                        </p>
                    </div>
                </div>
                <div className="mt-4 flex space-x-3">
                    <button className="px-3 py-1.5 text-sm text-yellow-700 hover:text-yellow-900">
                        Stay on Page
                    </button>
                    <button className="px-3 py-1.5 text-sm text-yellow-700 hover:text-yellow-900">
                        Leave Anyway
                    </button>
                </div>
            </div>

            {/* Info Message */}
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                <div className="flex">
                    <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-3" />
                    <div>
                        <h3 className="font-medium text-blue-900">Information</h3>
                        <p className="text-sm text-blue-700 mt-1">
                            Scheduled maintenance will occur on Sunday at 2 AM EST.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    Error404,
    Error500,
    NetworkError,
    EmptyStates,
    LoadingStates,
    StatusMessages
};
