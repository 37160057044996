import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Suspense} from "react";
import {allRoutes} from "@router/all.routers.jsx";
import {AuthProvider} from "./contexts/AuthContext";
import ProtectedRoute from "./router/ProtectedRoute";
import MainLayout from "./layouts/MainLayout";
import PublicLayout from "./layouts/PublicLayout";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {MeetingProvider} from "@/contexts/MeetingContext.jsx";
import {TaskProvider} from "@/contexts/TaskContext.jsx";
import {TooltipProvider} from "@/components/ui/tooltip.jsx";
import LoadingSpinner from "@/components/common/spinner.jsx";
import {ToastProvider} from "@/components/ui/toast.jsx";
import {Outlet} from "react-router-dom";

const RouteWrapper = ({layout, component: Component, isProtected}) => {
  const content = Component ? <Component/> : <Outlet/>;

  if (layout === "mainLayout" && isProtected) {
    return (
      <ProtectedRoute>
        <MainLayout>
          {content}
        </MainLayout>
      </ProtectedRoute>
    );
  }

  if (layout === "auth") {
    return content;
  }

  if (isProtected) {
    return (
      <ProtectedRoute>
        {content}
      </ProtectedRoute>
    );
  }

  if (layout === "none") {
    return content;
  }

  return (
    <PublicLayout>
      {content}
    </PublicLayout>
  );
};

const renderRoutes = (routes) => {
  return routes.map(({layout, path, component, isProtected, children}) => {
    // Create the route element
    const element = (
      <RouteWrapper
        layout={layout}
        component={component}
        isProtected={isProtected}
      />
    );

    // If there are children, return a Route with nested Routes
    if (children && children.length > 0) {
      return (
        <Route key={path} path={path} element={element}>
          {children.map((child) => (
            <Route
              key={`${path}/${child.path}`}
              path={child.path}
              element={
                <RouteWrapper
                  layout={child.layout || layout}
                  component={child.component}
                  isProtected={child.isProtected ?? isProtected}
                />
              }
            />
          ))}
        </Route>
      );
    }

    // If no children, return a simple Route
    return <Route key={path} path={path} element={element}/>;
  });
};

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner/>}>
        <GoogleOAuthProvider
          clientId={`973115181403-g7vt4mqfpbpe89rk5ovsditf67dg4bju.apps.googleusercontent.com`}
        >
          <ToastProvider>
            <TooltipProvider>
              <AuthProvider>
                <MeetingProvider>
                  <TaskProvider>
                    <Routes>
                      {renderRoutes(allRoutes)}
                    </Routes>
                  </TaskProvider>
                </MeetingProvider>
              </AuthProvider>
            </TooltipProvider>
          </ToastProvider>
        </GoogleOAuthProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;