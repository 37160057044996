export const MEETING_STATUSES = {
  IN_PROGRESS: 'in-progress',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export const MEETING_TYPES = {
  PLANNING: { label: 'Planning', emoji: '🎯' },
  DESIGN: { label: 'Design', emoji: '🎨' },
  TEAM_SYNC: { label: 'Team Sync', emoji: '👥' },
  CLIENT: { label: 'Client Meeting', emoji: '🤝' },
  ONE_ON_ONE: { label: '1:1', emoji: '💬' }
};

export const PLATFORMS = {
  GOOGLE_MEET: 'Google Meet',
  ZOOM: 'Zoom',
  MICROSOFT_TEAMS: 'Microsoft Teams'
};
