import React from "react";
import {
  Zap,
  ChartNoAxesCombined,
  ClipboardList,
  CalendarDays,
  Ticket,
  ServerCog,
  ChartPie,
  Calendar, CheckSquare, HelpCircle, MessageSquare, Settings, Link,
  LayoutDashboard, Video, Atom, List, FolderKanban, Clock, Filter,
  Users, Mail, PieChart, Bookmark, Share2, Plus, Bell, TableOfContents, ListTodo
} from "lucide-react";
import GoogleCallback from "@/pages/public/GoogleCallBack.jsx";
import ConnectCallback from "@/pages/integration/integration.callback.jsx";
import MeetingSettings from "@/pages/settings/meetings.jsx";
import TaskBoardView from "@/pages/tasks/board-view.jsx";
import WorkspaceSettings from "@/pages/workspace/WorkspaceSettings.jsx";
import AutomationHistory from "@/pages/automation/history.jsx";
import {Navigate} from "react-router-dom";
import {useProfileStore} from "@/store/slices/profile.slice.js";
import WorkspaceRoute from "@/router/WorkspaceRoute.jsx";
import WorkspaceUI from "@/pages/dashboard/allWorkspace.jsx";
import SeriesDetailsPage from "@/pages/meetings/series-details-page.jsx";
import SeriesOverview from "@/pages/meetings/series.jsx";

// Lazy load page components
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Tasks = React.lazy(() => import("@/pages/tasks"));
// const TasksCalender = React.lazy(() => import("@/pages/tasks/calendar-view"));
const Meetings = React.lazy(() => import("@/pages/meetings"));
// const Analytics = React.lazy(() => import("@/pages/analytics"));
const ProfileSettings = React.lazy(() => import("@/pages/settings/profile"));
const NotificationSettings = React.lazy(
  () => import("@/pages/settings/notifications"),
);
const SecuritySettings = React.lazy(() => import("@/pages/settings/security"));
const TeamSettings = React.lazy(() => import("@/pages/settings/team"));
// const LanguageSettings = React.lazy(() => import("@/pages/settings/language-region"));
// const AppearanceSettings = React.lazy(() => import("@/pages/settings/appearance"));
// const EmailSettings = React.lazy(() => import("@/pages/settings/email"));
const BillingSettings = React.lazy(() => import("@/pages/settings/billing"));
const Help = React.lazy(() => import("@/pages/help"));
const AutomationPage = React.lazy(() => import("@/pages/automation"));
const AutomationBuilder = React.lazy(
  () => import("@/pages/automation/builder"),
);
const Integrations = React.lazy(
  () => import("@/pages/integration/integrations"),
);
const LandingPage = React.lazy(() => import("@/pages/public/landing"));
const AuthPage = React.lazy(() => import("@/pages/auth/LoginPage"));
const VerifyEmailPage = React.lazy(
  () => import("@/pages/auth/verifyEmailPage"),
);
const ForgotPasswordPage = React.lazy(
  () => import("@/pages/auth/forgotPasswordPage"),
);
const ResetPasswordPage = React.lazy(() => import("@/pages/resetPasswordPage"));
const TaskCalendarView = React.lazy(() => import("@/pages/calendar"));
const OnboardingWelcome = React.lazy(() => import("@/pages/onboarding/index"));
const RegistrationWizard = React.lazy(
  () => import("@/pages/auth/RegistrationPage"),
);
const FeaturesPage = React.lazy(() => import("@/pages/public/FeaturePage"));
const PricingPage = React.lazy(() => import("@/pages/public/PricingPage"));
const OnboardingFlow = React.lazy(
  () => import("@/pages/onboarding/Onboarding"),
);
const Unauthorized = React.lazy(() => import("@/pages/auth/unauthorized"));

export const getWorkspacePath = (basePath, shortCode) => {
  if (basePath === "/all-workspaces") return basePath;
  return basePath.replace(":shortCode", shortCode || "");
};

export const getSubmenuContent = (routePath, shortCode) => {
  const normalizedPath = routePath?.replace(shortCode, ':shortCode');
  return submenuRoutes[normalizedPath] || null;
};

const prefix = "/:shortCode";


export const submenuRoutes = {
  [`/:shortCode/tasks`]: {
    groups: [
      {
        title: "Task Views",
        items: [
          {icon: LayoutDashboard, label: "Task Dashboard", path: "/:shortCode/tasks/dashboard"},
          {icon: List, label: "List View", path: "/:shortCode/tasks/list"},
          {icon: FolderKanban, label: "Kanban Board", path: "/:shortCode/tasks/board"},
          {icon: Calendar, label: "Calendar View", path: "/:shortCode/tasks/calendar"},
          {icon: Clock, label: "Timeline View", path: "/:shortCode/tasks/timeline"}
        ]
      },
      {
        title: "Organization",
        items: [
          {icon: List, label: "Labels", path: "/:shortCode/tasks/labels"},
          {icon: FolderKanban, label: "Categories", path: "/:shortCode/tasks/categories"},
          {icon: Users, label: "Projects", path: "/:shortCode/tasks/projects"}
        ]
      },
      {
        title: "Sources",
        items: [
          {icon: Mail, label: "Email Tasks", path: "/:shortCode/tasks/email"},
          {icon: MessageSquare, label: "Chat Tasks", path: "/:shortCode/tasks/chat"},
          {icon: Video, label: "Meeting Tasks", path: "/:shortCode/tasks/meetings"}
        ]
      }
    ]
  },
  [`/:shortCode/calendar`]: {
    groups: [
      {
        title: "Calendar Views",
        items: [
          {icon: Calendar, label: "Month View", path: "/:shortCode/calendar/month"},
          {icon: Calendar, label: "Week View", path: "/:shortCode/calendar/week"},
          {icon: Calendar, label: "Day View", path: "/:shortCode/calendar/day"},
          {icon: Calendar, label: "Agenda View", path: "/:shortCode/calendar/agenda"}
        ]
      },
      {
        title: "Events",
        items: [
          {icon: Clock, label: "Upcoming", path: "/:shortCode/calendar/upcoming"},
          {icon: Users, label: "Team Events", path: "/:shortCode/calendar/team"},
          {icon: Video, label: "Meetings", path: "/:shortCode/calendar/meetings"},
          {icon: Clock, label: "Recurring", path: "/:shortCode/calendar/recurring"}
        ]
      },
      {
        title: "Task Integration",
        items: [
          {icon: List, label: "Task Deadlines", path: "/:shortCode/calendar/deadlines"},
          {icon: Clock, label: "Time Blocks", path: "/:shortCode/calendar/time-blocks"},
          {icon: Users, label: "Team Availability", path: "/:shortCode/calendar/availability"}
        ]
      }
    ]
  },
  [`/:shortCode/events`]: {
    groups: [
      {
        title: "Event Management",
        items: [
          {icon: LayoutDashboard, label: "Event Dashboard", path: "/:shortCode/events/dashboard"},
          {icon: Calendar, label: "All Events", path: "/:shortCode/events"},
          {icon: Clock, label: "Upcoming Events", path: "/:shortCode/events/upcoming"},
          {icon: Video, label: "Virtual Events", path: "/:shortCode/events/virtual"},
          {icon: Bookmark, label: "Bookmarked", path: "/:shortCode/events/bookmarked"},
          {icon: TableOfContents, label: "Event Series", path: "/:shortCode/events/series"}
        ]
      },
      {
        title: "Event Types",
        items: [
          {icon: Video, label: "Meetings", path: "/:shortCode/events/meetings"},
          {icon: Users, label: "Team Sessions", path: "/:shortCode/events/team-sessions"},
          {icon: MessageSquare, label: "Workshops", path: "/:shortCode/events/workshops"},
          {icon: Calendar, label: "Recurring Events", path: "/:shortCode/events/recurring"}
        ]
      },
      {
        title: "Planning",
        items: [
          {icon: List, label: "Event Templates", path: "/:shortCode/events/templates"},
          {icon: Users, label: "Guest Management", path: "/:shortCode/events/guests"},
          {icon: Settings, label: "Resources", path: "/:shortCode/events/resources"},
          {icon: Clock, label: "Scheduling", path: "/:shortCode/events/scheduling"}
        ]
      },
      {
        title: "Analytics",
        items: [
          {icon: PieChart, label: "Attendance", path: "/:shortCode/events/attendance"},
          {icon: Clock, label: "Duration Stats", path: "/:shortCode/events/duration"},
          {icon: Filter, label: "Engagement", path: "/:shortCode/events/engagement"}
        ]
      }
    ]
  },
  [`/:shortCode/automation`]: {
    groups: [
      {
        title: "Workflow Automation",
        items: [
          {icon: Settings, label: "All Workflows", path: "/:shortCode/automation/workflows"},
          {icon: Plus, label: "Create Workflow", path: "/:shortCode/automation/create"},
          {icon: List, label: "Templates", path: "/:shortCode/automation/templates"},
          {icon: Clock, label: "Scheduled", path: "/:shortCode/automation/scheduled"}
        ]
      },
      {
        title: "Rules & Triggers",
        items: [
          {icon: Settings, label: "Task Rules", path: "/:shortCode/automation/task-rules"},
          {icon: Calendar, label: "Event Rules", path: "/:shortCode/automation/event-rules"},
          {icon: Bell, label: "Notifications", path: "/:shortCode/automation/notifications"},
          {icon: Clock, label: "Time Triggers", path: "/:shortCode/automation/time-triggers"}
        ]
      }
    ]
  }
};

export const sidebarRoutes = [
  {
    path: `${prefix}/dashboard`,
    icon: LayoutDashboard,
    label: "Dashboard",
    component: (props) => <WorkspaceRoute component={Dashboard} {...props} />,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/calendar`,
    icon: CalendarDays,
    label: "calendar",
    component: null,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
    children: [
      {
        icon: CalendarDays, label: "calendar", path: "", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Calendar, label: "Month View", path: "month", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Calendar, label: "Week View", path: "week", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Calendar, label: "Day View", path: "day", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Calendar, label: "Agenda View", path: "agenda", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Clock, label: "Upcoming", path: "upcoming", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Users, label: "Team Events", path: "team", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Video, label: "Meetings", path: "meetings", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Clock, label: "Recurring", path: "recurring", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: List, label: "Task Deadlines", path: "deadlines", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Clock, label: "Time Blocks", path: "time-blocks", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      },
      {
        icon: Users, label: "Team Availability", path: "availability", component: (props) => (
          <WorkspaceRoute component={TaskCalendarView} {...props} />
        ),
      }
    ]
  },
  {
    path: `${prefix}/tasks`,
    icon: ListTodo,
    label: "Tasks",
    component: null,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
    children: [
      {
        icon: List, label: "Tasks", path: "",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: List, label: "List View", path: "list",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: FolderKanban,
        label: "Kanban Board",
        path: "board",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Calendar,
        label: "Calendar View",
        path: "calendar",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Clock,
        label: "Timeline View",
        path: "timeline",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Clock,
        label: "Due Today",
        path: "due-today",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Filter,
        label: "High Priority",
        path: "priority",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Users, label: "Assigned to Me", path: "assigned",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Clock, label: "Overdue", path: "overdue",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: List, label: "Labels", path: "labels",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: FolderKanban, label: "Categories", path: "categories",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Users, label: "Projects", path: "projects",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Mail, label: "Email Tasks", path: "email",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: MessageSquare, label: "Chat Tasks", path: "chat",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      },
      {
        icon: Video, label: "Meeting Tasks", path: "meetings",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />
      }
    ],
  },
  {
    path: `${prefix}/events`,
    icon: Video,
    label: "Meetings",
    component: null,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
    children: [
      {
        path: "",  // Empty path means this is the index route
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />,
        isProtected: true
      },
      {
        path: "upcoming",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />,
        isProtected: true
      },
      {
        icon: Users, label: "My Events", path: "my-events",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Video, label: "Virtual Events", path: "virtual",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Bookmark, label: "Bookmarked", path: "bookmarked",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Share2, label: "Series View", path: "series",
        component: (props) => <WorkspaceRoute component={SeriesOverview} {...props} />,
      },
      {
        icon: Bookmark, label: "Bookmarked", path: "series/:seriesId",
        component: (props) => <WorkspaceRoute component={SeriesDetailsPage} {...props} />
      },
      {
        icon: Video, label: "Meetings", path: "meetings",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Users, label: "Team Sessions", path: "team-sessions",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: MessageSquare, label: "Workshops", path: "workshops",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Calendar, label: "Recurring Events", path: "recurring",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: List, label: "Event Templates", path: "templates",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Users, label: "Guest Management", path: "guests",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Settings, label: "Resources", path: "resources",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Clock, label: "Scheduling", path: "scheduling",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: PieChart, label: "Attendance", path: "attendance",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Clock, label: "Duration Stats", path: "duration",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      },
      {
        icon: Filter, label: "Engagement", path: "engagement",
        component: (props) => <WorkspaceRoute component={Meetings} {...props} />
      }
    ]
  },
  {
    path: `${prefix}/integrations`,
    icon: Link,
    label: "integrations",
    component: (props) => (
      <WorkspaceRoute component={Integrations} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/automation`,
    icon: Atom,
    label: "automation",
    component: (props) => (
      <WorkspaceRoute component={AutomationPage} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
    children: [
      {
        icon: Settings, label: "Automation", path: "", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Settings, label: "All Workflows", path: "/:shortCode/automation/workflows", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Plus, label: "Create Workflow", path: "/:shortCode/automation/create", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: List, label: "Templates", path: "/:shortCode/automation/templates", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Clock, label: "Scheduled", path: "/:shortCode/automation/scheduled", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Settings, label: "Task Rules", path: "/:shortCode/automation/task-rules", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Calendar, label: "Event Rules", path: "/:shortCode/automation/event-rules", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Bell, label: "Notifications", path: "/:shortCode/automation/notifications", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      },
      {
        icon: Clock, label: "Time Triggers", path: "/:shortCode/automation/time-triggers", component: (props) => (
          <WorkspaceRoute component={AutomationPage} {...props} />
        )
      }
    ]
  },
  // {
  //   path: `${prefix}/analytics`,
  //   icon: ChartPie,
  //   label: "Analytics",
  //   component: (props) => <WorkspaceRoute component={Analytics} {...props} />,
  //   location: "sideBar",
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
];

export const protectedRRoutes = [
  {
    path: "/all-workspaces",
    icon: LayoutDashboard,
    label: "Dashboard",
    component: WorkspaceUI,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/tasks/board-view`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={TaskBoardView} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/create/automation`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={AutomationBuilder} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/automation/:id/history`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={AutomationHistory} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/settings`,
    icon: Settings,
    label: "Settings",
    component: (props) => (
      <WorkspaceRoute component={ProfileSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/workspace-settings`,
    icon: Settings,
    label: "Settings",
    component: (props) => (
      <WorkspaceRoute component={WorkspaceSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  // {
  //   path: `${prefix}/tasks/calendar-view`,
  //   icon: CheckSquare,
  //   label: "Tasks",
  //   component: (props) => <WorkspaceRoute component={TasksCalender} {...props} />,
  //   location: "sideBar",
  //   layout: "mainLayout",
  //   isProtected: true,
  // },

  {
    path: `/notifications`,
    icon: LayoutDashboard,
    label: "notifications",
    component: (props) => (
      <WorkspaceRoute component={NotificationSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/security`,
    icon: LayoutDashboard,
    label: "security",
    component: (props) => (
      <WorkspaceRoute component={SecuritySettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/teams`,
    icon: LayoutDashboard,
    label: "teams",
    component: (props) => (
      <WorkspaceRoute component={TeamSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  // {
  //   path: "${prefix}/language",
  //   icon: LayoutDashboard,
  //   label: "language",
  //   component: (props) => <WorkspaceRoute component={LanguageSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  // {
  //   path: "${prefix}/appearance",
  //   icon: LayoutDashboard,
  //   label: "appearance",
  //   component: (props) => <WorkspaceRoute component={AppearanceSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  // {
  //   path: "${prefix}/email-settings",
  //   icon: LayoutDashboard,
  //   label: "emailSettings",
  //   component: (props) => <WorkspaceRoute component={EmailSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  {
    path: `/billings`,
    icon: LayoutDashboard,
    label: "billings",
    component: (props) => (
      <WorkspaceRoute component={BillingSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/meeting-settings`,
    icon: Calendar,
    label: "meetingSettings",
    component: (props) => (
      <WorkspaceRoute component={MeetingSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/integrations/google/callback",
    icon: LayoutDashboard,
    label: "billings",
    component: ConnectCallback,
    location: null,
    layout: "none",
    isProtected: true,
  },
];

export const allRoutes = [
  ...sidebarRoutes,
  ...protectedRRoutes,
  {
    path: "/auth/google/callback",
    icon: null,
    label: "GoogleCallback",
    component: GoogleCallback,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/",
    icon: null,
    label: "HomePage",
    component: LandingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/login",
    icon: null,
    label: "HomePage",
    component: AuthPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/register",
    icon: null,
    label: "HomePage",
    component: RegistrationWizard,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/features",
    icon: null,
    label: "HomePage",
    component: FeaturesPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/pricing",
    icon: null,
    label: "HomePage",
    component: PricingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/start-now",
    icon: null,
    label: "startTrial",
    component: OnboardingFlow,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/verify-email",
    icon: null,
    label: "verify",
    component: VerifyEmailPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/forget-password",
    icon: null,
    label: "verify",
    component: ForgotPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/reset-password",
    icon: null,
    label: "verify",
    component: ResetPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/unauthorized",
    icon: null,
    label: "unauthorized",
    component: Unauthorized,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/help",
    icon: HelpCircle,
    label: "help",
    component: Help,
    location: null,
    layout: null,
    isProtected: false,
  },
  {
    path: "/onboarding/welcome",
    icon: null,
    label: "welcome",
    component: OnboardingWelcome,
    location: null,
    layout: "none",
    isProtected: true,
  },
];

