import React, {useState} from "react";
import {
  AlertCircle,
  Calendar,
  Clock,
  Edit3,
  Grid,
  LayoutGrid,
  List,
  MoreHorizontal,
  Plus,
  Repeat,
  Settings,
  Trash2,
  Users,
  X
} from "lucide-react";
import AuthLayout from "@/components/common/layout/AuthLayout.jsx";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {Button} from "@/components/ui/button.jsx";

const SERIES_DATA = [
  {
    id: 1,
    title: "Team Standup",
    schedule: "Every Monday and Wednesday",
    time: "10:00 - 10:30 AM",
    participants: 12,
    totalEvents: 24,
    nextDate: "Feb 5, 2024",
    status: "active",
    platform: "Google Meet"
  },
  {
    id: 2,
    title: "Sprint Planning",
    schedule: "Every 2 weeks on Tuesday",
    time: "2:00 - 3:00 PM",
    participants: 8,
    totalEvents: 12,
    nextDate: "Feb 13, 2024",
    status: "active",
    platform: "Zoom"
  }
];

const SeriesCard = ({series, layout = "detailed"}) => {
  if (layout === "compact") {
    return (
      <div className="p-4 bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Repeat className="w-5 h-5 text-blue-500"/>
            <div>
              <h3 className="font-medium">{series.title}</h3>
              <p className="text-sm text-gray-500">{series.schedule}</p>
            </div>
          </div>
          <Menu as="div" className="relative">
            <MenuButton className="p-1 hover:bg-gray-100 rounded">
              <MoreHorizontal className="w-5 h-5 text-gray-500"/>
            </MenuButton>
            <MenuItems className="absolute right-0 z-50 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black/5">
              <MenuItem>
                <button className="flex items-center px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-50">
                  <Edit3 className="w-4 h-4 mr-2"/>
                  Edit Series
                </button>
              </MenuItem>
              <MenuItem>
                <button className="flex items-center px-4 py-2 text-sm text-red-600 w-full hover:bg-red-50">
                  <Trash2 className="w-4 h-4 mr-2"/>
                  Delete Series
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <Repeat className="w-5 h-5 text-blue-500"/>
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h3 className="font-medium">{series.title}</h3>
            <Menu as="div" className="relative">
              <MenuButton className="p-1 hover:bg-gray-100 rounded">
                <MoreHorizontal className="w-5 h-5 text-gray-500"/>
              </MenuButton>
              <MenuItems className="absolute right-0 z-50 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black/5">
                <MenuItem>
                  <button className="flex items-center px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-50">
                    <Edit3 className="w-4 h-4 mr-2"/>
                    Edit Series
                  </button>
                </MenuItem>
                <MenuItem>
                  <button className="flex items-center px-4 py-2 text-sm text-red-600 w-full hover:bg-red-50">
                    <Trash2 className="w-4 h-4 mr-2"/>
                    Delete Series
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>

          <div className="flex flex-wrap gap-2 mt-2">
            <span
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
              <Clock className="w-3 h-3 mr-1"/>
              {series.time}
            </span>
            <span
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-50 text-purple-700">
              <Calendar className="w-3 h-3 mr-1"/>
              {series.schedule}
            </span>
            <span
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
              <Users className="w-3 h-3 mr-1"/>
              {series.participants} participants
            </span>
          </div>

          <div className="mt-3 flex items-center text-sm text-gray-500">
            <span className="mr-4">Next: {series.nextDate}</span>
            <span>Total events: {series.totalEvents}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SeriesOverview = () => {
  const [layout, setLayout] = useState("detailed");

  return (
    <AuthLayout
      childrenHeader={
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <Repeat className="h-6 w-6 text-gray-900"/>
            <h1 className="text-xl font-bold text-gray-900">Event Series</h1>
          </div>

          <div className="flex items-center space-x-3">
            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-gray-900 hover:bg-gray-950 rounded-lg">
              <Plus className="h-4 w-4 mr-1.5"/>
              New Series
            </button>

            <Menu as="div" className="relative">
              <MenuButton className="p-2 text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded-lg">
                <Settings className="w-5 h-5"/>
              </MenuButton>
              <MenuItems className="absolute right-0 z-50 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black/5">
                <MenuItem>
                  <button className="flex items-center px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-50">
                    Manage Defaults
                  </button>
                </MenuItem>
                <MenuItem>
                  <button className="flex items-center px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-50">
                    Export Series
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      }
    >
      <div className="bg-white border-b">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center space-x-4 text-sm text-gray-500">
              <span>Active series: 5</span>
              <span className="text-gray-300">|</span>
              <span>Total events: 86</span>
            </div>
            <div className="flex items-center bg-gray-100 rounded-lg p-1">
              {[
                {id: "compact", icon: List},
                {id: "detailed", icon: LayoutGrid}
              ].map(({id, icon: Icon}) => (
                <button
                  key={id}
                  className={`p-1.5 rounded ${
                    layout === id ? "bg-white shadow-sm" : "text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setLayout(id)}
                >
                  <Icon className="w-4 h-4"/>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div
          className={`${layout === "compact" ? "space-y-4" : "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4"}`}>
          {SERIES_DATA.map((series) => (
            <SeriesCard key={series.id} series={series} layout={layout}/>
          ))}
        </div>
      </div>
    </AuthLayout>
  );
};

export default SeriesOverview;