import {
    ChevronLeft, Clock, Edit2, Calendar, FileText,
    Play, Users, CheckSquare, Video, Trash2, MoreVertical, AlertTriangle, AlertCircle
} from 'lucide-react';
import {Error404} from "@/components/common/errors/ErrorPages.jsx";
import {useMemo, useState} from "react";
import {format} from "date-fns";
import {Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {MEETING_TYPES} from "@/constants/calendar.js";
import {
    AlertDialog,
    AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader, AlertDialogTitle,
    AlertDialogTrigger
} from "@/components/ui/alert-dialog.jsx";
import AuthLayout from "@/components/common/layout/AuthLayout.jsx";

const UpcomingMeetings = ({ meetings }) => {
    const [sortBy, setSortBy] = useState('date');

    const sortedMeetings = useMemo(() => {
        return [...meetings].sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
        });
    }, [meetings]);

    return (
      <div className="bg-white rounded-lg border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
              <h2 className="text-lg font-medium">Upcoming Meetings</h2>
              <div className="flex items-center space-x-2">
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="text-sm border border-gray-200 rounded-md px-3 py-1.5"
                  >
                      <option value="date">Sort by Date</option>
                      <option value="attendees">Sort by Attendees</option>
                  </select>
              </div>
          </div>

          <div className="divide-y divide-gray-200">
              {sortedMeetings.map((meeting) => (
                <div key={meeting.id} className="p-6 hover:bg-gray-50 transition-colors">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="flex flex-col">
                                <h3 className="font-medium text-gray-900">{format(new Date(meeting.date), 'EEEE, MMMM d')}</h3>
                                <p className="text-sm text-gray-500 mt-1">
                                    {meeting.startTime} - {meeting.endTime}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-3">
                <span className="text-sm text-gray-500">
                  {meeting.participants.length} participants
                </span>
                            <button className="px-4 py-2 bg-blue-600 text-white text-sm rounded-lg hover:bg-blue-700">
                                Join Meeting
                            </button>
                            <button className="p-2 text-gray-400 hover:text-gray-600 rounded-lg">
                                <MoreVertical className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </div>
              ))}

              {meetings.length === 0 && (
                <div className="p-12 text-center">
                    <Calendar className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">No upcoming meetings</h3>
                    <p className="text-gray-500">There are no scheduled meetings in this series.</p>
                </div>
              )}
          </div>
      </div>
    );
};

const PastMeetings = ({ meetings }) => {
    return (
      <div className="space-y-6">
          <div className="bg-white rounded-lg border border-gray-200">
              <div className="px-6 py-4 border-b border-gray-200">
                  <h2 className="text-lg font-medium">Past Meetings</h2>
              </div>

              <div className="divide-y divide-gray-200">
                  {meetings.map((meeting) => (
                    <div key={meeting.id} className="p-6 hover:bg-gray-50 transition-colors">
                        <div className="flex items-center justify-between mb-4">
                            <div>
                                <h3 className="font-medium text-gray-900">
                                    {format(new Date(meeting.date), 'EEEE, MMMM d')}
                                </h3>
                                <p className="text-sm text-gray-500 mt-1">
                                    {meeting.startTime} - {meeting.endTime}
                                </p>
                            </div>
                            <div className="flex items-center space-x-3">
                                <button className="flex items-center text-sm text-gray-600 hover:text-gray-900">
                                    <FileText className="h-4 w-4 mr-2" />
                                    View Notes
                                </button>
                                <button className="flex items-center text-sm text-gray-600 hover:text-gray-900">
                                    <Play className="h-4 w-4 mr-2" />
                                    Watch Recording
                                </button>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4 text-sm">
                            <div>
                                <p className="text-gray-500 mb-1">Attendance</p>
                                <p className="font-medium">{meeting.participants.length} participants</p>
                            </div>
                            <div>
                                <p className="text-gray-500 mb-1">Duration</p>
                                <p className="font-medium">45 minutes</p>
                            </div>
                            <div>
                                <p className="text-gray-500 mb-1">Action Items</p>
                                <p className="font-medium">5 completed</p>
                            </div>
                        </div>
                    </div>
                  ))}
              </div>
          </div>
      </div>
    );
};

const SeriesAnalytics = ({ series }) => {
    return (
      <div className="space-y-6">
          {/* Attendance Trends */}
          <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-lg font-medium mb-6">Attendance Trends</h3>
              <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={series.meetings}>
                          <XAxis dataKey="date" tickFormatter={(date) => format(new Date(date), 'MMM d')} />
                          <YAxis />
                          <Tooltip />
                          <Line type="monotone" dataKey="participants.length" stroke="#3B82F6" />
                      </LineChart>
                  </ResponsiveContainer>
              </div>
          </div>

          {/* Meeting Duration */}
          <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-lg font-medium mb-6">Meeting Duration</h3>
              <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={series.meetings}>
                          <XAxis dataKey="date" tickFormatter={(date) => format(new Date(date), 'MMM d')} />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="duration" fill="#3B82F6" />
                      </BarChart>
                  </ResponsiveContainer>
              </div>
          </div>

          {/* Participation Stats */}
          <div className="grid grid-cols-3 gap-6">
              <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Most Active Participants</h4>
                  <div className="space-y-4">
                      {series.participants.slice(0, 5).map((participant, index) => (
                        <div key={index} className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                    <span className="text-sm text-blue-600">
                      {participant.email.charAt(0).toUpperCase()}
                    </span>
                                </div>
                                <span className="text-sm font-medium">{participant.email}</span>
                            </div>
                            <span className="text-sm text-gray-500">{participant.attendance}%</span>
                        </div>
                      ))}
                  </div>
              </div>
          </div>
      </div>
    );
};

const EditSeriesModal = ({ series, onClose }) => {
    const [formData, setFormData] = useState({
        title: series.title,
        type: series.type,
        startTime: series.startTime,
        endTime: series.endTime,
        platform: series.platform,
        recurringPattern: series.recurringPattern,
        participants: series.participants
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle series update logic
        onClose();
    };

    return (
      <Modal onClose={onClose} title="Edit Series">
          <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                  <label className="block text-sm font-medium text-gray-700">Series Title</label>
                  <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  />
              </div>

              <div>
                  <label className="block text-sm font-medium text-gray-700">Meeting Type</label>
                  <select
                    value={formData.type.label}
                    onChange={(e) => setFormData({ ...formData, type: { label: e.target.value } })}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  >
                      {MEETING_TYPES.map(type => (
                        <option key={type.label} value={type.label}>
                            {type.emoji} {type.label}
                        </option>
                      ))}
                  </select>
              </div>

              <div className="grid grid-cols-2 gap-4">
                  <div>
                      <label className="block text-sm font-medium text-gray-700">Start Time</label>
                      <input
                        type="time"
                        value={formData.startTime}
                        onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      />
                  </div>
                  <div>
                      <label className="block text-sm font-medium text-gray-700">End Time</label>
                      <input
                        type="time"
                        value={formData.endTime}
                        onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      />
                  </div>
              </div>

              {/* Recurring Pattern */}
              <div>
                  <label className="block text-sm font-medium text-gray-700">Recurrence</label>
                  <div className="mt-2 space-y-4">
                      <select
                        value={formData.recurringPattern.type}
                        onChange={(e) => setFormData({
                            ...formData,
                            recurringPattern: { ...formData.recurringPattern, type: e.target.value }
                        })}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2"
                      >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                      </select>
                  </div>
              </div>

              <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg"
                  >
                      Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  >
                      Save Changes
                  </button>
              </div>
          </form>
      </Modal>
    );
};

const DeleteSeriesModal = ({ series, onClose, onDelete }) => {
    return (
      <AlertDialog open={series} onOpenChange={onClose} onAction={onDelete}>
          <AlertDialogTrigger>Open</AlertDialogTrigger>
          <AlertDialogContent>
              <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete your account
                      and remove your data from our servers.
                  </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction>Continue</AlertDialogAction>
              </AlertDialogFooter>
          </AlertDialogContent>
      </AlertDialog>
    );
};

// components for the details page
const StatCard = ({ label, value, icon: Icon }) => (
  <div className="bg-white rounded-lg border border-gray-200 p-6">
      <div className="flex items-center justify-between">
          <div>
              <p className="text-sm font-medium text-gray-500">{label}</p>
              <p className="mt-2 text-3xl font-semibold text-gray-900">{value}</p>
          </div>
          <div className="h-12 w-12 bg-blue-50 rounded-lg flex items-center justify-center">
              <Icon className="h-6 w-6 text-blue-600" />
          </div>
      </div>
  </div>
);

const TabButton = ({ children, active, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors
      ${active
      ? 'bg-blue-50 text-blue-600'
      : 'text-gray-600 hover:bg-gray-100'}`}
  >
      {children}
  </button>
);


const SeriesDetailsPage = () => {
    const [activeTab, setActiveTab] = useState('upcoming');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const series = {
        "id": 1,
        "title": "Weekly Team Sync",
        "type": {
            "label": "Team Sync",
            "emoji": "🤝"
        },
        "startTime": "10:00",
        "endTime": "10:45",
        "platform": "Zoom",
        "recurringPattern": {
            "type": "weekly",
            "interval": "Monday",
            "frequency": 1
        },
        "totalMeetings": 24,
        "participants": [
            {
                "email": "sarah.johnson@company.com",
                "attendance": 95
            },
            {
                "email": "mike.smith@company.com",
                "attendance": 92
            },
            {
                "email": "emma.davis@company.com",
                "attendance": 88
            },
            {
                "email": "alex.wong@company.com",
                "attendance": 85
            },
            {
                "email": "david.miller@company.com",
                "attendance": 82
            }
        ],
        "meetings": [
            {
                "id": "m1",
                "date": "2024-12-02",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "scheduled",
                "participants": [
                    "sarah.johnson@company.com",
                    "mike.smith@company.com",
                    "emma.davis@company.com",
                    "alex.wong@company.com",
                    "david.miller@company.com"
                ],
                "duration": 45
            },
            {
                "id": "m2",
                "date": "2024-11-25",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "completed",
                "participants": [
                    "sarah.johnson@company.com",
                    "mike.smith@company.com",
                    "emma.davis@company.com",
                    "david.miller@company.com"
                ],
                "duration": 42,
                "recording": "meeting-recording-m2.mp4",
                "notes": "meeting-notes-m2.md"
            },
            {
                "id": "m3",
                "date": "2024-11-18",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "completed",
                "participants": [
                    "sarah.johnson@company.com",
                    "mike.smith@company.com",
                    "emma.davis@company.com",
                    "alex.wong@company.com",
                    "david.miller@company.com"
                ],
                "duration": 45,
                "recording": "meeting-recording-m3.mp4",
                "notes": "meeting-notes-m3.md"
            },
            {
                "id": "m4",
                "date": "2024-11-11",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "completed",
                "participants": [
                    "sarah.johnson@company.com",
                    "emma.davis@company.com",
                    "alex.wong@company.com",
                    "david.miller@company.com"
                ],
                "duration": 40,
                "recording": "meeting-recording-m4.mp4",
                "notes": "meeting-notes-m4.md"
            },
            {
                "id": "m5",
                "date": "2024-12-09",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "scheduled",
                "participants": [
                    "sarah.johnson@company.com",
                    "mike.smith@company.com",
                    "emma.davis@company.com",
                    "alex.wong@company.com",
                    "david.miller@company.com"
                ],
                "duration": 45
            },
            {
                "id": "m6",
                "date": "2024-12-16",
                "startTime": "10:00",
                "endTime": "10:45",
                "status": "scheduled",
                "participants": [
                    "sarah.johnson@company.com",
                    "mike.smith@company.com",
                    "emma.davis@company.com",
                    "alex.wong@company.com",
                    "david.miller@company.com"
                ],
                "duration": 45
            }
        ]
    }

    if (!series) return <Error404 />;

    const stats = [
        {
            label: 'Total Meetings',
            value: series.totalMeetings,
            icon: Calendar,
        },
        {
            label: 'Average Duration',
            value: '45m',
            icon: Clock,
        },
        {
            label: 'Attendance Rate',
            value: '89%',
            icon: Users,
        },
        {
            label: 'Action Items',
            value: '45',
            icon: CheckSquare,
        },
    ];

    return (
      <div className="min-h-screen bg-gray-50">
          <AuthLayout>
          {/* Header */}
          <div className="bg-white border-b">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="py-6">
                      {/* Navigation */}
                      <div className="flex items-center space-x-4 mb-4">
                          <button
                            // onClick={() => router.back()}
                            className="p-2 hover:bg-gray-100 rounded-lg"
                          >
                              <ChevronLeft className="h-5 w-5 text-gray-500" />
                          </button>
                          {series && (
                            <div>
                                <div className="flex items-center space-x-3">
                                    <span className="text-2xl">{series.type.emoji}</span>
                                    <h1 className="text-2xl font-semibold text-gray-900">
                                        {series.title}
                                    </h1>
                                </div>
                                <div className="flex items-center space-x-4 mt-1 text-sm text-gray-500">
                                    <div className="flex items-center">
                                        <Calendar className="h-4 w-4 mr-1"/>
                                        Every {series.recurringPattern.interval} {series.recurringPattern.type}
                                    </div>
                                    <div className="flex items-center">
                                        <Clock className="h-4 w-4 mr-1"/>
                                        {series.startTime} - {series.endTime}
                                    </div>
                                    <div className="flex items-center">
                                        <Video className="h-4 w-4 mr-1"/>
                                        {series.platform}
                                    </div>
                                </div>
                            </div>
                          )}
                      </div>

                      {/* Actions */}
                      <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-4">
                              {/* Tabs */}
                              <TabButton
                                active={activeTab === 'upcoming'}
                                onClick={() => setActiveTab('upcoming')}
                              >
                                  Upcoming
                              </TabButton>
                              <TabButton
                                active={activeTab === 'past'}
                                onClick={() => setActiveTab('past')}
                              >
                                  Past Meetings
                              </TabButton>
                              <TabButton
                                active={activeTab === 'analytics'}
                                onClick={() => setActiveTab('analytics')}
                              >
                                  Analytics
                              </TabButton>
                          </div>

                          <div className="flex items-center space-x-3">
                              <button
                                onClick={() => setShowEditModal(true)}
                                className="flex items-center px-3 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-lg"
                              >
                                  <Edit2 className="h-4 w-4 mr-2" />
                                  Edit Series
                              </button>
                              <button
                                onClick={() => setShowDeleteModal(true)}
                                className="flex items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg"
                              >
                                  <Trash2 className="h-4 w-4 mr-2" />
                                  Delete Series
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          {/* Content */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              {/* Stats */}
              <div className="grid grid-cols-4 gap-6 mb-8">
                  {stats.map((stat, index) => (
                    <StatCard key={index} {...stat} />
                  ))}
              </div>

              {/* Main Content */}
              {activeTab === 'upcoming' && (
                <UpcomingMeetings meetings={series.meetings.filter(m => new Date(m.date) > new Date())} />
              )}

              {activeTab === 'past' && (
                <PastMeetings meetings={series.meetings.filter(m => new Date(m.date) < new Date())} />
              )}

              {activeTab === 'analytics' && (
                <SeriesAnalytics series={series} />
              )}
          </div>

          {/* Modals */}
          {showEditModal && (
            <EditSeriesModal
              series={series}
              onClose={() => setShowEditModal(false)}
            />
          )}

          {showDeleteModal && (
            <DeleteSeriesModal
              series={series}
              onClose={() => setShowDeleteModal(false)}
              onDelete={() => {
                  // Handle series deletion
                  router.push('/series');
              }}
            />
          )}
          </AuthLayout>
      </div>
    );
};

export default SeriesDetailsPage;
