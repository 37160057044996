import React from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/utils/utils.js";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getWorkspacePath } from "@/router/all.routers.jsx";
import { useProfileStore } from "@/store/slices/profile.slice.js";

export const SettingsSidebar = ({
  navigationGroups,
  activeItem,
  setActiveItem,
}) => {
  const { currentWorkspace } = useProfileStore();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-64 border-r border-border min-h-screen bg-background">
      <ScrollArea className="flex-1">
        <div className="space-y-4 p-6">
          {navigationGroups.map((group, index) => (
            <div key={group.name}>
              <div className="space-y-3">
                {/* Group Header */}
                <div className="flex items-center gap-2 px-2">
                  <div className="p-2 rounded-md bg-primary/10">
                    <group.icon className="h-4 w-4 text-primary" />
                  </div>
                  <h4 className="text-sm font-semibold tracking-tight">
                    {group.name}
                  </h4>
                </div>

                {/* Navigation Items */}
                <div className="space-y-1">
                  {group.items.map((item) => (
                    <TooltipProvider key={item.id}>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            className={cn(
                              "w-full justify-start gap-3 p-2 h-auto",
                              activeItem === item.id &&
                                "bg-primary/10 text-primary hover:bg-primary/15",
                            )}
                            onClick={() => {
                              setActiveItem(item.id);
                              navigate({
                                pathname: getWorkspacePath(
                                  "/:shortCode/workspace-settings",
                                  currentWorkspace?.shortCode,
                                ),
                                search: createSearchParams({
                                  id: item.id,
                                }).toString(),
                              });
                            }}
                          >
                            <div
                              className={cn(
                                "p-1 rounded-md transition-colors",
                                activeItem === item.id
                                  ? "text-primary"
                                  : "text-muted-foreground",
                              )}
                            >
                              <item.icon className="h-4 w-4" />
                            </div>
                            <div className="flex flex-col items-start gap-1">
                              <span
                                className={cn(
                                  "text-sm font-medium",
                                  activeItem === item.id
                                    ? "text-primary"
                                    : "text-foreground",
                                )}
                              >
                                {item.label}
                              </span>
                            </div>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" className="max-w-[200px]">
                          <p className="text-sm">{item.help}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </div>
              </div>
              {index < navigationGroups.length - 1 && (
                <Separator className="my-6" />
              )}
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};
