import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext.jsx";
import ProfileDropdown from "../profileDropdown.jsx";
import { Clock, Sparkles } from "lucide-react";
import { useProfileStore } from "@/store/slices/profile.slice.js";

export default function Header() {
  const { isAuthenticated } = useAuth();
  const { currentWorkspace } = useProfileStore();

  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 3600));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  return (
    <>
      {/* Announcement Banner */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-2 px-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Sparkles className="h-4 w-4" />
            <span>
              Special Launch Offer: Save 50% + Get Premium Features Free
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="h-4 w-4" />
            <span>Ends in: {formatTime(timeLeft)}</span>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="text-2xl font-bold text-gray-900">
                <Link to={`/`}>TaskWeave</Link>
              </div>
              <div className="ml-2 bg-blue-100 text-blue-800 text-xs font-bold px-2 py-1 rounded-full">
                BETA
              </div>
            </div>
            <div className="flex items-center gap-8">
              <div className="hidden md:flex gap-6">
                <Link
                  to="/features"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Features
                </Link>
                <Link
                  to="/pricing"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Pricing
                </Link>
              </div>
              {isAuthenticated ? (
                <ProfileDropdown workspace={currentWorkspace} />
              ) : (
                <div className={`gap-3 flex`}>
                  <Link
                    to={`/start-now`}
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                  >
                    Start Free Trial
                  </Link>
                  <Link
                    to={`/login`}
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                  >
                    Sign In
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
