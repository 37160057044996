import React, { useState, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  BarChart,
  Users,
  Search,
  Plus,
  Filter,
  Clock,
  ArrowUpDown,
  Star,
  Archive,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Progress } from "@/components/ui/progress";
import AuthLayout from "@/components/common/layout/AuthLayout.jsx";
import { useProfileStore } from "@/store/slices/profile.slice.js";
import { getWorkspacePath } from "@/router/all.routers.jsx";
import { useNavigate } from "react-router-dom";

const WorkspacesOverview = () => {
  const { workspaces, updateCurrentWorkspace } = useProfileStore();
  const route = useNavigate();

  // const [workspaces, setWorkspaces] = useState([
  //   {
  //     id: 1,
  //     name: "Personal Tasks",
  //     description: "Personal project management and daily tasks",
  //     members: 1,
  //     tasksCount: 12,
  //     completedTasks: 5,
  //     lastAccessed: "2 hours ago",
  //     type: "personal",
  //     starred: true,
  //     progress: 42,
  //     status: "active",
  //     activity: 85
  //   },
  //   {
  //     id: 2,
  //     name: "Marketing Team",
  //     description: "Campaign planning and content management",
  //     members: 8,
  //     tasksCount: 45,
  //     completedTasks: 30,
  //     lastAccessed: "1 hour ago",
  //     type: "team",
  //     starred: false,
  //     progress: 67,
  //     status: "active",
  //     activity: 92
  //   },
  // ]);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("lastAccessed");
  const [filterType, setFilterType] = useState("all");
  const [view, setView] = useState("grid");

  // Computed/filtered workspaces
  const filteredWorkspaces = useMemo(() => {
    return workspaces
      .filter((workspace) => {
        const matchesSearch =
          workspace.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          workspace.description
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        const matchesFilter =
          filterType === "all" ||
          (filterType === "starred" && workspace.starred) ||
          (filterType === "active" && workspace.status === "active");
        return matchesSearch && matchesFilter;
      })
      .sort((a, b) => {
        switch (sortBy) {
          case "name":
            return a.name.localeCompare(b.name);
          case "members":
            return b.members - a.members;
          case "progress":
            return b.progress - a.progress;
          case "activity":
            return b.activity - a.activity;
          default:
            return 0;
        }
      });
  }, [workspaces, searchQuery, sortBy, filterType]);

  const toggleStar = (id) => {
    updateCurrentWorkspace(
      workspaces.map((w) => (w.id === id ? { ...w, starred: !w.starred } : w)),
    );
  };

  const archiveWorkspace = (id) => {
    updateCurrentWorkspace(
      workspaces.map((w) => (w.id === id ? { ...w, status: "archived" } : w)),
    );
  };

  // Analytics summary
  const analytics = useMemo(
    () => ({
      totalWorkspaces: workspaces.length,
      activeProjects: workspaces.filter((w) => w.status === "active").length,
      totalTasks: workspaces.reduce((acc, w) => acc + w.tasksCount, 0),
      completionRate: Math.round(
        (workspaces.reduce((acc, w) => acc + w.completedTasks, 0) /
          workspaces.reduce((acc, w) => acc + w.tasksCount, 0)) *
          100,
      ),
    }),
    [workspaces],
  );

  const handleWorkspaceClick = (id) => {
    const workspace = workspaces.find((w) => w.id === id);
    updateCurrentWorkspace(workspace);
    route(getWorkspacePath("/:shortCode/dashboard", workspace?.shortCode));
  };

  return (
    <AuthLayout showSidebar={false}>
      <div className="p-6 max-w-7xl mx-auto">
        {/* Enhanced Header with Analytics */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h1 className="text-3xl font-bold mb-2">My Workspaces</h1>
              <p className="text-gray-500">
                Manage and track your workspace portfolio
              </p>
            </div>
            <div className="flex gap-4">
              <Button
                variant="outline"
                onClick={() => setView(view === "grid" ? "list" : "grid")}
              >
                {view === "grid" ? "List View" : "Grid View"}
              </Button>
              <Button className="flex items-center gap-2">
                <Plus className="h-4 w-4" />
                New Workspace
              </Button>
            </div>
          </div>

          {/* Analytics Cards */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
            <Card>
              <CardContent className="pt-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Total Workspaces</p>
                  <Users className="h-4 w-4 text-gray-500" />
                </div>
                <p className="text-2xl font-bold">
                  {analytics.totalWorkspaces}
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Active Projects</p>
                  <BarChart className="h-4 w-4 text-gray-500" />
                </div>
                <p className="text-2xl font-bold">{analytics.activeProjects}</p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Total Tasks</p>
                  <Clock className="h-4 w-4 text-gray-500" />
                </div>
                <p className="text-2xl font-bold">{analytics.totalTasks}</p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Completion Rate</p>
                  <Progress value={analytics.completionRate} className="w-20" />
                </div>
                <p className="text-2xl font-bold">
                  {analytics.completionRate}%
                </p>
              </CardContent>
            </Card>
          </div>

          {/* Enhanced Search and Filters */}
          <div className="flex flex-col md:flex-row gap-4 mb-8">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-3 h-4 w-4 text-gray-500" />
              <Input
                placeholder="Search workspaces..."
                className="pl-10"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Select value={filterType} onValueChange={setFilterType}>
              <SelectTrigger className="w-[180px]">
                <Filter className="h-4 w-4 mr-2" />
                <SelectValue placeholder="Filter by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Workspaces</SelectItem>
                <SelectItem value="starred">Starred</SelectItem>
                <SelectItem value="active">Active</SelectItem>
              </SelectContent>
            </Select>
            <Select value={sortBy} onValueChange={setSortBy}>
              <SelectTrigger className="w-[180px]">
                <ArrowUpDown className="h-4 w-4 mr-2" />
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="name">Name</SelectItem>
                <SelectItem value="members">Members</SelectItem>
                <SelectItem value="progress">Progress</SelectItem>
                <SelectItem value="activity">Activity</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Enhanced Workspace Grid */}
        <div
          className={
            view === "grid"
              ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              : "flex flex-col gap-4"
          }
        >
          {filteredWorkspaces.map((workspace) => (
            <Card
              key={workspace.id}
              className={`hover:shadow-lg transition-shadow cursor-pointer ${
                view === "list" ? "flex flex-row items-center" : ""
              }`}
              onClick={() => handleWorkspaceClick(workspace.id)}
            >
              <CardHeader className={view === "list" ? "flex-1" : ""}>
                <div className="flex justify-between items-start mb-2">
                  <div className="flex items-center gap-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => toggleStar(workspace.id)}
                      className={
                        workspace.starred ? "text-yellow-500" : "text-gray-400"
                      }
                    >
                      <Star className="h-4 w-4" />
                    </Button>
                    <CardTitle className="text-xl">{workspace.name}</CardTitle>
                  </div>
                  <div className="flex items-center gap-2">
                    <Badge
                      variant={
                        workspace.type === "personal" ? "secondary" : "default"
                      }
                    >
                      {workspace.type}
                    </Badge>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm">
                          •••
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          onClick={() => archiveWorkspace(workspace.id)}
                        >
                          <Archive className="h-4 w-4 mr-2" />
                          Archive
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <CardDescription>{workspace.description}</CardDescription>
              </CardHeader>

              <CardContent className={view === "list" ? "flex-1" : ""}>
                <div className="space-y-4">
                  <div className="flex justify-between items-center text-sm text-gray-500">
                    <div className="flex items-center gap-4">
                      <div>
                        {workspace.members}{" "}
                        {workspace.members === 1 ? "member" : "members"}
                      </div>
                      <div>{workspace.tasksCount} tasks</div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="flex justify-between text-sm">
                      <span>Progress</span>
                      <span>{workspace.progress}%</span>
                    </div>
                    <Progress value={workspace.progress} />
                  </div>
                </div>
              </CardContent>

              <CardFooter className="flex justify-between items-center">
                <div className="text-sm text-gray-500">
                  Last accessed {workspace.lastAccessed}
                </div>
                {workspace.type === "team" && (
                  <div className="flex -space-x-2">
                    {[...Array(Math.min(3, workspace.members))].map((_, i) => (
                      <Avatar key={i} className="border-2 border-white w-8 h-8">
                        <AvatarFallback>
                          {String.fromCharCode(65 + i)}
                        </AvatarFallback>
                      </Avatar>
                    ))}
                    {workspace.members > 3 && (
                      <div className="w-8 h-8 rounded-full bg-gray-100 border-2 border-white flex items-center justify-center text-sm text-gray-600">
                        +{workspace.members - 3}
                      </div>
                    )}
                  </div>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </AuthLayout>
  );
};

export default WorkspacesOverview;
