import { createContext, useContext, useEffect, useState } from "react";
import api from "@/config/axios.js";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [labels, setLabels] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedTask) {
      setLoading(true);
      api
        .get(`/task/tasks/${selectedTask}`)
        .then((res) => {
          setTaskDetails(res);
          setIsTaskDrawerOpen(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [selectedTask]);

  return (
    <TaskContext.Provider
      value={{
        labels,
        setLabels,
        selectedTask,
        setSelectedTask,
        isTaskDrawerOpen,
        setIsTaskDrawerOpen,
        taskDetails,
        loading,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error("useTask must be used within a TaskProvider");
  }
  return context;
};
